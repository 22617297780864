import React from "react";
import CommonProduct from "../components/commonPage"

class ValidationsPage extends CommonProduct {
    interoperability:any
    privacy:any
    pedagogy:any
    usability:any
    accessibility:any
    research:any

    constructor(props) {
        super(props)
        this.firstRender = true
    }

    processAnchor() {
        let anchorParts = location.href.split("#",2)
        let anchor = null
        if (anchorParts.length>1)
        {
            anchor = anchorParts[1]
        }
        if (!!anchor)
        {
            if (!!this[anchor]) {
                setTimeout(() => { 
                    this[anchor].scrollIntoView({
                        // optional params
                        behavior: 'smooth',
                        block: 'start',
                        inline: 'center',
                    })
                }, 10)
            }
        }
        return null
    }
    
    render()
    {
        let BaseComponent = this.BaseComponentRender.bind(this)
        return (
            <BaseComponent controller={this} menu="validations_page" title="Product Validations | EdTech Index" className="page">
            <div className="blurb">
                <h1>Product Validations</h1>
                
                <p className="statement">
                    <strong>The EdTech Index now includes validated information from trusted third-party sources to illuminate key areas to consider when exploring and vetting new tools.</strong> We are working alongside partners on a collective vision: to provide education leaders and educators with easy access to valuable information, including alignments, badges, certifications about edtech <strong>interoperability, privacy, accessibility, usability and efficacy</strong> to find the right tools for learners. These validations are critical but might be hard to find, so we've provided links to expert organizations who can help you and your team dig deeper as you progress along your product decision-making journey.
                </p>
                <p className="noBtmPad">
                    Read the descriptions below to learn more about the different validation areas that will be included in the Index and why they are critical to helping you determine if a product or tool will meet your needs. <strong>ISTE will continue to work with <a href="/about/">partners</a> and other stakeholders across the field to add new domains and validations to the </strong> to bring better visibility to critical product information for making informed decisions. If you are interested in becoming a validations partner, please reach out to us.
                </p>
            </div>
            <ol className="top_level">
            <li className="inner" ref={ref => {
                this.privacy = ref;
            }}><div className="heading"><i className="fal fa-user-lock"></i><br/>Safe</div>
                <p>
                It's essential that educators keep student data private and secure. This is a requirement of federal and state laws governing schools and data sharing for minors. Even while protecting the privacy of student data, schools can still allow controlled, secure access by trusted individuals when doing so helps students learn and helps educators do their jobs better. According to <a href="https://fpf.org/wp-content/uploads/2016/12/Beyond-One-Classroom.pdf" target="_blank">the 2016 Future of Privacy Forum Survey</a>, the overwhelming majority of parents surveyed support the collection and use of the following student data by educators and administrators in schools or at the district level for educational purposes: grades, attendance records, special needs status, standardized test scores and disciplinary records. Getting data privacy and security right can mean a secure and interoperable data ecosystem that helps educators and parents better understand a student's needs and successes, but getting it wrong can mean headaches, frustrated families and possibly running afoul of state or federal law.
                </p>
                <p><strong>
                See how a product adheres to best practices in privacy by looking for the following validation information:
                </strong>
                </p>
                <img id="commonSense" alt="The Common Sense Privacy Rating" src="../images/Copy of H-LOGO-Common_Sense-RGB.svg"/>
                <div className="clear"/>
                <h3>The Common Sense Privacy Rating</h3>
                <p  className="noBtmPad">
                    This <a href="https://privacy.commonsense.org/" target="_blank">program</a> evaluates solution providers' privacy policies so that parents and teachers can make smart choices about the learning tools they use with their children and students, and so that schools and districts can participate in improving the technology used in K–12 classrooms.
                </p>

                <br/>

                <img id="sealOfAlignment" alt="The Future of Privacy Forum Student Privacy Pledge" src="../images/Badge 1 - SPP.png"/>
                <h3>The Future of Privacy Forum Student Privacy Pledge</h3>
                <p className="noBtmPad">
                    <a href="https://studentprivacypledge.org/faqs/" target="_blank">Student Privacy Pledge</a> commitments are intended to concisely detail existing federal law and regulatory guidance regarding the collection and handling of student data, and to encourage service providers to more clearly articulate these practices. Accountability comes from the Federal Trade Commission (FTC), which has the authority to bring civil enforcement actions against companies who do not adhere to their public statements of practices.
                </p>
                <br/>
                <br/>

                <img id="sealOfAlignment" alt="iKeepSafe COPPA Safe Harbor Certification" src="../images/iKS_COPPA.png"/>
                <h3>iKeepSafe COPPA Safe Harbor Certification</h3>
                <p className="noBtmPad">
                    The <a href="https://ikeepsafe.org/certification/coppa/" target="_blank">COPPA Safe Harbor Certification</a> program demonstrates that practices surrounding collection, use, maintenance and disclosure of personal information from children under the age of 13 are consistent with principles and requirements of the Children's Online Privacy Protection Act (COPPA).
                </p>
                <br/>
                <br/>
                <br/>

                <img id="sealOfAlignment" alt="iKeepSafe FERPA Certification" src="../images/iKS_FERPA.png"/>
                <h3>iKeepSafe FERPA Certification</h3>
                <p className="noBtmPad">
                    The <a href="https://ikeepsafe.org/certification/ferpa/" target="_blank">FERPA Certification</a> program demonstrates compliance with the Family Educational Rights and Privacy Act (FERPA), a federal law that applies to all schools that receive funds from the US Department of Education. It protects the privacy of student education records, and allows parents and eligible students certain rights related to review, control and privacy of those records.
                </p>


                <br/>
                <br/>

                <img id="edtech_data_privacy" alt="1EdTech Data Privacy Seal" src="../images/New TrustEd Apps Data Privacy Certified Badge.png"/>
                <h3>1EdTech Data Privacy Seal</h3>
                <p className="noBtmPad" >The 1EdTech <a href="https://www.1edtech.org/program/trustedapps" target="_blank">Seal of Data Privacy</a> is awarded once an application's privacy policy and terms of service successfully meet the criteria outlined on the <a href="https://www.imsglobal.org/activity/app-vetting/rubric-light" target="_blank">1EdTech TrustEd Apps Data Privacy Rubric</a> and demonstrate compliance with applicable privacy laws like <a href="https://www.ftc.gov/tips-advice/business-center/guidance/complying-coppa-frequently-asked-questions" target="_blank">COPPA</a>, <a href="https://www2.ed.gov/policy/gen/guid/fpco/ferpa/index.html" target="_blank">FERPA</a>, and <a href="https://gdpr.eu/" target="_blank">GDPR</a>. This seal signifies a commitment to a superior level of student data privacy.</p>


                </li>

                <li className="inner" ref={ref => {
                this.research = ref;
            }}><div className="heading"><i className="fal fa-file-search"></i><br/>Evidence-Based<br/><span className="unstrong"></span> </div>
                    <p>
                    Evidence takes shape in several forms. This can come in the form of an academic research study demonstrating statistically significant effects on improving student outcomes. It can mean contextual studies focused on demonstrating efficacy for a particular school or group of learners. It can also take the form of demonstrating careful adherence to learning science principles in the design and development of an offering. In the EdTech Index, we identified “evidence-based” as products that have demonstrated:
                    <ol>
                    <li>Efficacy aligned to learning sciences and UX research (ISTE Seal), </li>
                    <li>An understanding and commitment to products built on rigorous research (Digital Promise Research-Based Design badge), </li>
                    <li>Align with ESSA evidence tiers (Instructure and Leanlab “Building Evidence” certification), </li>
                    <li>An ongoing commitment to incorporating teacher implementation feedback in a structured codesign process (Leanlab Codesign)</li>
                    </ol>
                    </p>
                    <p><strong>See how products have demonstrated research-based design and development by looking for the following validation information:</strong></p>

                    <img id="sealOfAlignment" alt="ISTE Seal" src="../images/iste_seal.png"/>
                    <h3>ISTE Seal</h3>
                    <p className="noBtmPad">
                        <a href="https://iste.org/iste-seal" target="_blank">ISTE’s Seal program</a> reviews and recognizes products for their methods and implementation of digital pedagogy and technological usability with the ISTE Standards. 
                        Products submitted to the program undergo a rigorous review by a panel of education and technology experts. Resources and products designed with digital pedagogy, technological usability, and the ISTE Standards in mind 
                        are choosing to demonstrate their commitment to supporting critical digital-age learning skills and knowledge. Check out the <a href="https://iste.org/edtech-product-selection" target="_blank">research here</a>. Regardless of a solution’s intended grade level, purpose, or content area, by 
                        earning an ISTE Seal, a solution is shown to consciously, purposefully, and meaningfully support best practices for digital-age teaching and learning.
                    </p>

                    <br/><br/><br/>

                    <img className="digitalPromise" alt="ISTE Seal" src="../images/DP_Research_3.png"/>
                    <h3>Digital Promise Evidence-Based Edtech: ESSA Tier 3</h3>
                    <p className="noBtmPad">
                    Products that have earned <a href="https://productcertifications.microcredentials.digitalpromise.org/explore/evidence-based-edtech-essa-tier-3-product-certific" target="_blank">Evidence-Based Edtech: ESSA Tier 3</a>
                    &nbsp; demonstrate that research about how people learn is core to the theoretical framework that drives product design and is evidenced throughout the product. Evidence collected from a well-designed research study 
                    indicates that the edtech product is designed for a positive learning experience. The evidence supporting the product aligns to Tier 3 of the ESSA Tiers of Evidence and is readily accessible to the public.

                    </p>

                    <br/><br/><br/><br/>

                    <img className="digitalPromise" alt="Digital Promise Research-Based Design: ESSA Tier 4" src="../images/Research-Based Design_ ESSA Tier 4 badge.png"/>
                    <h3>Digital Promise Research-Based Design: ESSA Tier 4</h3>
                    <p className="noBtmPad">
                    Products that have earned the <a href="https://productcertifications.microcredentials.digitalpromise.org/explore/research-based-design-essa-tier-4-product-certific" target="_blank">Research-Based Design: ESSA Tier 4</a> product certification demonstrate that research about how people learn is core to the theoretical framework that drives product design and is evidenced throughout the product. Evidence collected from a well-designed qualitative research study indicates that the edtech product is designed for a positive learning experience. The evidence supporting the product aligns to Tier 4 of the ESSA Tiers of Evidence and is readily accessible to the public.
                    </p>

                    <br/><br/><br/><br/><br/>

                    <img className="digitalPromise alt" alt="Digital Promise Practitioner-Informed Design" src="../images/DP_Practioner.png"/>
                    <h3>Digital Promise Practitioner-Informed Design</h3>
                    <p className="noBtmPad">
                    Products that have earned the <a href="https://productcertifications.microcredentials.digitalpromise.org/explore/practitioner-informed-design-product-certificati-3" target="_blank">Practitioner-Informed Design</a> product certification demonstrate that the product developer team centers educator expertise and priorities. The developer team embraces a learning culture to iteratively improve product design through multiple instances of practitioner partnerships.
                    </p>

                    <br/><br/><br/><br/><br/><br/><br/>

                    <img className="digitalPromise" alt="Instructure ESSA Evidence Badges" src="../images/ESSA-Badges_ESSA-1.png"/>
                    <h3>Instructure ESSA Evidence Badges</h3>
                    <p className="noBtmPad">
                    ESSA requires federal funds be used for interventions that meet one of four levels of evidence. <a href="https://www.instructure.com/resources/product-overviews/practical-rigor-our-approach-producing-and-validating-essa-aligned?filled" target="_blank">Instructure's ESSA Evidence Badges™</a>, awarded through a rigorous rubric-based process carried out by What Works Clearinghouse (WWC) certified researchers, can be earned by any solution and equip educators to quickly understand a solution’s existing evidence base.
                    </p>

                    <br/><br/><br/><br/>

                    <img className="digitalPromise" alt="Leanlab Codesign Product Certification" src="../images/Codesign Certification Badge.png"/>
                    <h3>Leanlab Codesign Product Certification</h3>
                    <p className="noBtmPad">
                    Products that have earned Leanlab's <a href="https://www.credly.com/org/leanlab-education/badge/codesign-product-certification" target="_blank">Codesign Product Certification</a> have demonstrated an ongoing commitment to the values of codesign, with the product incorporating more than 75% of the recommendations made for product improvements by teachers and students during a codesign research study.
                    </p>

                    <br/><br/><br/><br/>

                    <img className="digitalPromise" alt="Leanlab Building Evidence Certification" src="../images/Building-Evidence-Badge.png"/>
                    <h3>Leanlab Building Evidence Certification</h3>
                    <p className="noBtmPad">
                    Products that have earned Leanlab's <a href="https://www.credly.com/org/leanlab-education/badge/building-evidence-badge" target="_blank">Building Evidence Certification</a> have completed a logic model or theory of change that is based on rigorous research and have a plan or research already underway to study the effects of the edtech product. The logic model and research plan align with Tier IV of the ESSA Tiers of Evidence.
                    </p>

                    <br/><br/><br/>

                    <img className="digitalPromise" alt="ICEIE Effectiveness Certification" src="../images/EFFECTIVENESS 2024 Gold.png"/>
                    <h3>ICEIE Effectiveness Certification</h3>
                    <p className="noBtmPad">
                    The International Certification of Evidence of Impact of Education (<a href="https://static1.squarespace.com/static/62fc80ec4c86a26330d18835/t/665aeb086f594a43f7417a0d/1717234440545/ICEIE_Efficacy+and+Effectiveness+Criteria+and+Indicators+2024+updated+Feb+24.pdf" target="_blank">Effectiveness</a>) badge evaluates edtech solutions and criteria from a systematic review of 64 research frameworks. Bronze certifications reflect teacher reviews for classroom feasibility, Silver certifications require observational classroom studies, and Gold certifications demand replicated cost-effective studies in multiple settings.
                    </p>

                    <br/><br/><br/>

                    <img className="digitalPromise" alt="ICEIE Efficacy Certification" src="../images/EFFICACY 2024 Gold.png"/>
                    <h3>ICEIE Efficacy Certification</h3>
                    <p className="noBtmPad">
                    The International Certification of Evidence of Impact of Education (<a href="https://static1.squarespace.com/static/62fc80ec4c86a26330d18835/t/665aeb086f594a43f7417a0d/1717234440545/ICEIE_Efficacy+and+Effectiveness+Criteria+and+Indicators+2024+updated+Feb+24.pdf" target="_blank">Efficacy</a>) badge evaluates edtech solutions and criteria from a systematic review of 64 research frameworks. Bronze certifications confirm alignment with published studies, Silver certifications require correlational testing, and Gold certifications require randomized trials in classrooms.
                    </p>
                </li>
                <li className="inner" ref={ref => {
                this.accessibility = ref;
                this.processAnchor()
            }}><div className="heading"><i className="fal fa-universal-access"></i><br/>Inclusive</div>
                    <p>
                        Accessibility is essential for people with learning difficulties, including those with cognitive, visual, auditory, neurological and physical disabilities. Learning technology accessibility means that solutions are designed so that learners with any disability can perceive, understand, navigate and interact with them. Even those who experience limitations such as bright sunlight or an environment where they cannot listen to audio, or those who have limited internet connection can benefit from learning solutions that are accessible. Digital learning technologies with advanced accessibility features help all learners thrive in traditional and virtual learning environments.
                    </p>
                    <p className="noBtmPad">
                        The universal standard to website accessibility are the <a href="https://www.w3.org/WAI/standards-guidelines/wcag/" target="_blank">Website Content Accessibility Guidelines</a> (WCAG), collaboratively developed and designed to ensure the <a href="https://aem.cast.org/create/designing-accessibility-pour" target="_blank">POUR</a> principles (content is perceivable, operable, understandable and robust). 
                    </p>
                    <br/>
                    <p><strong>See how a product aligns to best practices in usability by looking for the following validation information:</strong></p>
                    <br/>

                    <img id="sealOfAlignment" alt="ISTE Seal" src="../images/iste_seal.png"/>
                    <h3>ISTE Seal</h3>
                    <p className="noBtmPad">
                        <a href="https://iste.org/iste-seal" target="_blank">ISTE’s Seal program</a> reviews and recognizes products for their methods and implementation of digital pedagogy and technological usability with the ISTE Standards. 
                        Products submitted to the program undergo a rigorous review by a panel of education and technology experts. Resources and products designed with digital pedagogy, technological usability, and the ISTE Standards in mind 
                        are choosing to demonstrate their commitment to supporting critical digital-age learning skills and knowledge. Check out the <a href="https://iste.org/edtech-product-selection" target="_blank">research here</a>. Regardless of a solution’s intended grade level, purpose, or content area, by 
                        earning an ISTE Seal, a solution is shown to consciously, purposefully, and meaningfully support best practices for digital-age teaching and learning.
                    </p>

                    <br/><br/><br/>

                    <img className="digitalPromise" alt="Digital Promise Learner Variability Certification" src="../images/Digital-Promise-Learner-02.png"/>
                    <h3>Digital Promise Learner Variability Certification</h3>
                    <p className="noBtmPad">
                        Products that have earned a <a href="https://productcertifications.microcredentials.digitalpromise.org/explore/learner-variability-product-certification-2" target="_blank">Learner Variability Certification</a> demonstrate an understanding of and commitment to building a product with features that can meet learners' varying needs. The solution provider can publicly share how the product's design supports variation among learners.
                    </p>
                    
                    <br/><br/><br/>

                    <img className="digitalPromise" alt="CAST Accessibility Baseline for the UDL Certification" src="../images/CAST-UDL-badge 1.png"/>
                    <h3>CAST Accessibility Baseline for the UDL Certification</h3>
                    <p className="noBtmPad">
                        The <a href="https://productcertifications.microcredentials.digitalpromise.org/explore/accessibility-baseline-for-the-universal-design-fo" target="_blank">certified product</a>&nbsp;provides information about its conformance to the accessibility principles defined in the Web Content Accessibility Guidelines (<a href="https://www.w3.org/WAI/standards-guidelines/wcag/" target="_blank">WCAG</a>) in an accessible format. This certification serves as one prerequisite for the full UDL Product Certification (along with the <a href="https://productcertifications.microcredentials.digitalpromise.org/explore/alignment-to-the-universal-design-for-learning-fra" target="_blank">Alignment to the UDL Framework certification</a>).
                    </p>
                    
                    <br/><br/><br/>

                    <img className="digitalPromise" alt="CAST Universal Design for Learning Certification" src="../images/CAST-UDL-badge 5.png"/>
                    <h3>CAST Universal Design for Learning Certification</h3>
                    <p className="noBtmPad">
                        Products that have earned the <a href="https://productcertifications.microcredentials.digitalpromise.org/explore/udl-product-certification-january-2023-v2" target="_blank">UDL Certification</a> demonstrate that accessibility is prioritized in the product design, the product has clearly, transparently, and publicly shared its commitment and conformance with <a href="https://www.w3.org/WAI/standards-guidelines/wcag/" target="_blank">WCAG v2.x</a>, and the product provides transparent communication about accessibility practices and conformance to customers.
                    </p>
                </li>
                <li className="inner" ref={ref => {
                this.usability = ref;
            }}><div className="heading"><i className="fal fa-phone-laptop"></i><br/>Usable <br/><span className="unstrong"></span></div>
                    <p>
                    <a href="https://www.interaction-design.org/literature/topics/usability" target="_blank">Usability</a> is a measure of how well a user in a specific context can use a product to achieve a goal effectively and efficiently. Usability is ultimately about behavior, and in educational contexts, the usability of a product or service must take into account education's key stakeholders—teachers and learners.
                    </p>
                    <p>
                        An education solution must have a user-centered design. Beyond adhering to user experience principles, solution providers are now intentionally designing elements that take into account and support teachers' needs and competencies as well as learners' cognitive abilities, social-emotional needs and personal backgrounds.
                    </p>
                    <p><strong>See how a product aligns to best practices in usability by looking for the following validation information:</strong></p>

                    <img id="sealOfAlignment" alt="ISTE Seal" src="../images/iste_seal.png"/>
                    <h3>ISTE Seal</h3>
                    <p className="noBtmPad">
                        <a href="https://iste.org/iste-seal" target="_blank">ISTE’s Seal program</a> reviews and recognizes products for their methods and implementation of digital pedagogy and technological usability with the ISTE Standards. 
                        Products submitted to the program undergo a rigorous review by a panel of education and technology experts. Resources and products designed with digital pedagogy, technological usability, and the ISTE Standards in mind 
                        are choosing to demonstrate their commitment to supporting critical digital-age learning skills and knowledge. Check out the <a href="https://iste.org/edtech-product-selection" target="_blank">research here</a>. Regardless of a solution’s intended grade level, purpose, or content area, by 
                        earning an ISTE Seal, a solution is shown to consciously, purposefully, and meaningfully support best practices for digital-age teaching and learning.
                    </p>

                    <br/><br/><br/>

                    <img className="digitalPromise" alt="Digital Promise Learner Variability Certification" src="../images/Digital-Promise-Learner-02.png"/>
                    <h3>Digital Promise Learner Variability Certification</h3>
                    <p className="noBtmPad">
                        Products that have earned a <a href="https://productcertifications.microcredentials.digitalpromise.org/explore/learner-variability-product-certification-2" target="_blank">Learner Variability Certification</a> demonstrate an understanding of and commitment to building a product with features that can meet learners' varying needs. The solution provider can publicly share how the product's design supports variation among learners.
                    </p>
                    


                </li>
                <li className="inner" ref={ref => {
                this.interoperability = ref;
            }}><div className="heading"><i className="fal fa-random"></i><br/>Interoperable</div>
                <p>According to <a href="https://www.projectunicorn.org/what-is-interoperability" target="_blank">Project Unicorn</a>, interoperability refers to the seamless, secure and controlled exchange of data between systems and applications. Data interoperability <a href="https://www.edsurge.com/news/2020-11-19-why-the-pandemic-forces-administrators-to-rethink-attendance-and-interoperability" target="_blank">continues to be</a> a major concern, impacting learning, procurement decisions and a solution's effectiveness. Administrators want to help learners by analyzing data from various tools compiled into one dashboard. Teachers want to avoid manually entering student data into several systems and to personalize learning while spending less time interpreting data in multiple forms. Students and parents want a clear picture of academic performance compiled in a readable format from various learning environments. This underscores the need for greater interoperability in learning technology solutions. Without interoperability, schools also bear the financial burden of manually performing tasks that applications should do automatically. This builds hidden costs into every solution that lacks interoperability support.
                </p>


                <p><strong>See how a product is aligned with best practices in interoperability by looking for the following validation information:</strong></p>


                <img id="unicorn" alt="Project Unicorn Interoperability Badge" src="../images/Tier_4_TM_v2.png"/>

                <h3>Project Unicorn Interoperability Certification</h3>
                <p className="noBtmPad" >The <a href="https://www.projectunicorn.org/interoperability-certification" target="_blank">Project Unicorn Interoperability Certification</a> signals that an edtech product prioritizes data interoperability. There are four tiered badges available representing levels of interoperability, with tier 4 equaling the highest standard. At the core of interoperability is a focus on better informing instruction and driving student-centered learning experiences while ensuring data is secure. This interoperability certification enables school systems to know they are investing in products that prioritize data modernization and ensuring that their systems &quot;talk to each other&quot; in a secure and seamless manner.
                </p>

                <br/>
                <br/>
                
                <img id="edtech_certified" alt="1EdTech Interoperability Certification" src="../images/1EdTech_Certified.png"/>
                <h3>1EdTech Interoperability Certification</h3>
                <p className="noBtmPad" ><a href="https://www.1edtech.org/certification/get-certified" target="_blank">1EdTech Certification</a> indicates that a product has passed rigorous interoperability testing powered by <a href="https://www.1edtech.org/" target="_blank">1EdTech open standards</a> (e.g., OneRoster<sup>&reg;</sup>, LTI<sup>&reg;</sup>, Caliper Analytics<sup>&reg;</sup> and Open Badges). 1EdTech Certification ensures that educational technology tools, content, and platforms reliably work together resulting in time savings, better user experiences, and the widest possible choice of innovative products now and in the future.</p>

                <br/>
                <br/>
                
                <img id="edfi_certified" alt="Ed-Fi Certifications and Badges" src="../images/Ed-Fi Logo.png"/>
                <h3>Ed-Fi Certifications and Badges</h3>
                <p className="noBtmPad"><a href="https://www.ed-fi.org/certifications-and-badges/" target="_blank">Ed-Fi Validations</a> showcase adherence to standardized use of core elements in the Ed-Fi Data Standard. Certifications are granted to data providers passing rigorous tests for compliance with Ed-Fi data standards and APIs for data exchange in their products. Ed-Fi Badges require vendors to deliver products and services using Ed-Fi best practices.</p>

                </li>

            </ol>

            </BaseComponent>
        )
    }
}
export default ValidationsPage